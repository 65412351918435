<template>
 <div>
  <vx-card>

    <div class="my-5 text-center gap-2 flex">
        <!-- <input type="text" name="from" id="from" placeholder="از تاریخ" v-model="fromDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black"> -->
        <!-- <input type="text" name="from" id="from" placeholder="تا تاریخ" v-model="toDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black"> -->


        <vue-button-spinner
          >
          <span @click="showModal" >
          ثبت رویداد جدید
          </span>

        </vue-button-spinner>
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="getEvents" >
          به روزرسانی رویدادها
          </span>

        </vue-button-spinner>
    </div>
    <EventModal
          v-show="isModalVisible"
          @close="closeModal"
          @reloadevents="getEvents"
        />
    <div v-if="isData">
      <vs-pagination class="my-3" :total="page_count" v-model="page"></vs-pagination>

        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" >
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> عنوان </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> شرح رویداد </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> مربوط به نود </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> تاریخ رویداد </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> تاریخ ثبت </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> گزارش دهنده </span>

        </div>
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" :key="event" v-for="event in events">
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ event.title }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ event.description }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ event.node_id }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ event.event_date }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ event.created_at }} </span>
            <span dir="ltr" class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ event.user_info.name }} </span>
        </div>
        <vs-pagination class="my-3" :total="page_count" v-model="page"></vs-pagination>

    </div>

</vx-card>
 </div>



</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'
import EventModal from './EventModal.vue';

    export default {
        components: {
          VueButtonSpinner,
          EventModal
        },
        data: () => ({
            fromDate:'',
            toDate:'',
            events:[],
            isData:false,
            isLoading: false,
            status: '',
            isModalVisible: false,
            page_count: 0,
            page: 1,
            reloadme: 0

        }),
        watch: {
          page() {
            this.getEvents()
          },
          reloadme() {
            this.getEvents()
          }

        },
        methods: {
            showModal() {
              this.isModalVisible = true;
            },
            closeModal() {
              this.isModalVisible = false;
            },
            getEvents() {
                 this.isLoading = true
                 this.$http.get(
                    '/admin/events'
                 ).then((res) => {
                    this.events = res.data.params.data;
                    this.isData = true
                    this.page_count = res.data.params.last_page
                    this.isLoading = false
                    this.status = false // or success
                 }).catch(error => {
                    this.$vs.notify({
                      text: error.response.data.message,
                      color: "danger",
                    });
                    this.isLoading = false
                    this.status = true //or error
                 })
            }
        },

        created() {
          this.getEvents()
        }

    }
</script>
