<template>
  <modal
      mytitle="ثبت رخداد جدید"
      @close="$emit('close')">
      <div slot="body">
          <form @submit.prevent="onSubmit">
              <!-- Name -->
              <div class="vx-row justify-center">
                <div class="form-item" :class="{ errorInput: $v.title.$error }">

                    <div class="flex flex-col">
                    عنوان                    <p class="errorText" v-if="!$v.title.required"> پر کردن عنوان اجباری است</p>

                    </div>
                    <div class="flex flex-col">
                    <input
                        v-model="title"
                        :class="{ error: $v.title.$error }+' border-0 rounded bg-grey-light px-2 py-2 text-black'"
                        @change="$v.title.$touch()">
                        </div>
                </div>
              </div>
              <div class="vx-row justify-center">
                <div class="form-item" :class="{ errorInput: $v.description.$error }">


                    <div class="flex flex-col">
                    شرح رخداد<p class="errorText" v-if="!$v.description.required"> پر کردن شرح رخداد اجباری است</p>
                    </div>

                    <div class="flex flex-col">
                    <textarea
                        v-model="description"
                        :class="{ error: $v.description.$error }+' border-0 rounded bg-grey-light px-2 py-2 text-black'"
                        @change="$v.description.$touch()"></textarea>
                        </div>
                </div>
              </div>
              <div class="vx-row justify-center">
                <div class="form-item" :class="{ errorInput: $v.node_id.$error }">

                    <p class="errorText" v-if="!$v.node_id.required"> Field is required!</p>
                    <div class="flex flex-col">
                    مرتبط با نود
                    </div>
                    <div class="flex flex-col">
                    <input
                        v-model="node_id"
                        :class="{ error: $v.node_id.$error }+' border-0 rounded bg-grey-light px-2 py-2 text-black'"
                        @change="$v.node_id.$touch()">
                        </div>
                </div>
              </div>
              <div class="vx-row justify-center">
                <div class="form-item" >

                    <!-- <p class="errorText" v-if="!$v.event_date.required"> Field is required!</p> -->
                    <div class="flex flex-col">
                    تاریخ رخداد
                    </div>
                    <div class="flex flex-col">
                    <date-picker-fa v-model="event_date"  size="sm" class="border-0 rounded bg-grey-light px-4 py-2 text-black" placeholder="تاریخ وقوع رخداد را انتخاب کنید" locale="fa" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD"/>

                    </div>
                </div>
              </div>
              <div class="vx-row justify-center">
                <div class="form-item" :class="{ errorInput: $v.event_time.$error }">

                    <!-- <p class="errorText" v-if="!$v.event_time.required"> Field is required!</p> -->
                    <div class="flex flex-col">
                    ساعت رخداد
                    </div>
                    <div class="flex flex-col">
                      <date-picker-fa v-model="event_time" type="time" />

                    </div>
                </div>
              </div>
              <br>
              <!-- Button -->
              <vue-button-spinner
                :is-loading="isLoading"
                :disabled="isLoading"
                class="btn px-6 py-2 bg-secondary rounded border-0 text-white"
                >
                <span @click="onSubmit" >
                ثبت رخداد
                </span>

              </vue-button-spinner>
              <!-- <button class="btn px-6 py-2 bg-secondary rounded border-0 text-white">ثبت</button> -->
          </form>
      </div>
  </modal>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import VueButtonSpinner from 'vue-button-spinner'

import modal from './Modal.vue'
const date = new Date();
export default {
  components: {
      modal,
      VueButtonSpinner
  },
  watch: {
    event_date() {
    }
  },
  data () {
      return {
          title: '',
          description: '',
          node_id: '',
          event_date: '',
          event_time: date.getHours()+":"+this.addZero(date.getMinutes()),
          isLoading: false,
          status: ''
      }
  },
  validations: {
      title: {
          required
      },
      description: {
          required
      },
      node_id: {
        numeric
      },
      event_date: {
          // required
      },
      event_time: {
          // required
      }
  },
  methods: {
      addZero(i) {
        if (i < 10) {i = "0" + i}
        return i;
      },
      onSubmit () {
          this.$v.$touch()
          if(!this.$v.$invalid) {
              const user = {
                  title: this.title,
                  description: this.description,
                  node_id: this.node_id,
                  event_date: this.event_date,
                  event_time: this.event_time != '' ? this.event_time+':00' : ''
              }
              this.isLoading = true
              this.$http.post(
                '/admin/events', user
              ).then((res) => {
                this.isLoading = false
                this.status = false // or success
                // this.logs = res.data.params.data;
                this.isData = true
                this.$vs.notify({
                  text: res.data.message,
                  color: "success",
                });
                this.$emit('reloadevents')
              }).catch(error => {
                this.$vs.notify({
                  text: error.response.data.message,
                  color: "danger",
                });
                this.isLoading = false
                this.status = true //or error
              })

              //DONE
              this.title = ''
              this.description = ''
              this.node_id = ''
              this.event_date = ''
              this.event_time = ''
              this.$v.$reset()
              this.$emit('close')
          }
      }
  }
}
</script>

<style lang="scss">
.theme-dark form  {
  color: #000000 !important;
}
.form-item .errorText {
  display: none;
  margin-bottom: 8px;
  font-size: 13.4px;
  color: red;
}

.form-item {
  &.errorInput .errorText {
      display: block;
  }
}

input.error {
  border-color: red;
}
</style>
